import React, { Fragment, useState, useEffect } from "react";
import Header from "../../components/Header";
import Carousel from 'react-bootstrap/Carousel';
import BannerImage from "../../assets/images/mdp/mdp-banner.png"
import C1Image from "../../assets/images/mdp/c1.png"
import C2Image from "../../assets/images/mdp/c2.png"
import C3Image from "../../assets/images/mdp/c3.png"
import C4Image from "../../assets/images/mdp/c4.png"
import C5Image from "../../assets/images/mdp/c5.png"
import C6Image from "../../assets/images/mdp/c6.png"
import Img1Image from "../../assets/images/mdp/1.png"
import Img2Image from "../../assets/images/mdp/2.png"
import Img3Image from "../../assets/images/mdp/3.png"
import Img4Image from "../../assets/images/mdp/4.png"
import Img5Image from "../../assets/images/mdp/5.png"
import Img6Image from "../../assets/images/mdp/6.png"
import Img7Image from "../../assets/images/mdp/7.png"
import Img8Image from "../../assets/images/mdp/8.png"
import Img9Image from "../../assets/images/mdp/9.png"
import Img10Image from "../../assets/images/mdp/10.png"
import Imgmdp1 from "../../assets/images/mdp/mdp1.jpg"
import Imgmdp2 from "../../assets/images/mdp/mdp2.jpg"
import Imgmdp3 from "../../assets/images/mdp/mdp3.jpg"
import Imgmdp4 from "../../assets/images/mdp/mdp4.jpg"
import Imgmdp5 from "../../assets/images/mdp/mdp5.png"
import Imgmdp6 from "../../assets/images/mdp/mdp6.jpg"
import Img11Image from "../../assets/images/mdp/11.png"
import ImgNbaImage from "../../assets/images/mdp/nba.png"
import Img111Image from "../../assets/images/mdp/11-1.png"
import Img12Image from "../../assets/images/mdp/12.png"
import Img13Image from "../../assets/images/mdp/13.png"
import Img14Image from "../../assets/images/mdp/14.png"
import Img15Image from "../../assets/images/mdp/15.png"
import Img16Image from "../../assets/images/mdp/16.png"
import Img17Image from "../../assets/images/mdp/17.png"
import Img18Image from "../../assets/images/mdp/18.png"
import Img19Image from "../../assets/images/mdp/19.png"
import Img20Image from "../../assets/images/mdp/20.png"
import Img21Image from "../../assets/images/mdp/21.png"
import Img22Image from "../../assets/images/mdp/22.png"
import Img23Image from "../../assets/images/mdp/23.png"
import Img24Image from "../../assets/images/mdp/24.png"
import Img25Image from "../../assets/images/mdp/25.png"
import Img26Image from "../../assets/images/mdp/26.png"
import Img27Image from "../../assets/images/mdp/27.png"
import Img28Image from "../../assets/images/mdp/28.png"
import client1 from "../../assets/images/mdp/mdpclient1.png"
import client2 from "../../assets/images/mdp/mdpclient2.png"
import client3 from "../../assets/images/mdp/mdpclient3.png"
import client4 from "../../assets/images/mdp/mdpclient4.png"
import clien5 from "../../assets/images/mdp/mdpclient5.png"
import client6 from "../../assets/images/mdp/mdpclient6.png"
import client7 from "../../assets/images/mdp/mdpclient7.png"
import client8 from "../../assets/images/mdp/mdpclient8.png"
import client9 from "../../assets/images/mdp/mdpclient9.png"
import client10 from "../../assets/images/mdp/mdpclient10.png"
import client11 from "../../assets/images/mdp/mdpclient11.png"
import client12 from "../../assets/images/mdp/mdpclient12.png"
import HetalImage from '../../assets/images/mdp/hetal.png'
import ManmohanImage from "../../assets/images/mdp/Prof. Manmohan Vyas.png"


import MDPbrocher from '../../assets/pdf/MDPC-Brochure-MBA.pdf'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import { CarouselItem } from "react-bootstrap";
const Mdp = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let [PrashantStatement, setPrashantStatement] = useState(false);
    let [PurandareStatement, setPurandareStatement] = useState(false);
    let [KiranStatement, setKiranStatement] = useState(false);
    let [GuhaStatement, setGuhaStatement] = useState(false);
    let [RenuStatement, setRenuStatement] = useState(false);
    let [KomalStatement, setKomalStatement] = useState(false);
    let [SumitStatement, setSumitStatement] = useState(false);
    let [AbhijitStatement, setAbhijitStatement] = useState(false);
    let [UmeshStatement, setUmeshStatement] = useState(false);
    let [MrityunjayStatement, setMrityunjayStatement] = useState(false);
    let [TausifStatement, setTausifStatement] = useState(false);
    let [ManishaStatement, setManishaStatement] = useState(false);
    let [HetalStatement, setHetalStatement] = useState(false);
    let [MangeshStatement, setMangeshStatement] = useState(false);
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                {/* <h2 className="bannerTitleAbout leftAlignBannerTxt whiteText">MDP</h2> */}
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Management Development Programs & Consultancy (MDPC)</h3>
                    </div>
                </div>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="row paddingTop3em">
                        <div className="col-md-12">
                            <Tabs
                                defaultActiveKey={props.About}
                                id="uncontrolled-tab-example"
                                className="barColor transparentBackground"
                            >
                                <Tab eventKey="About" title="About Us" className="whiteBackground paddingBottom3em">
                                    <div className="container">
                                        <div className="row">
                                            <h3 className="blockTitle">About Us</h3>
                                            <p><b>Management Development Programs & Consultancy (MDPC)</b></p>
                                            <p>The objective of the MDPs is to help improve management systems and practices in India by providing relevant training to the
                                                executives of different sectors of the economy operating at different levels in the organizational hierarchy. The participants will be
                                                equipped with the latest tools, techniques and skills spanning different streams of management such as General Management,
                                                Human Resources, Soft Skills, Marketing, Finance.</p>
                                            <p>We immerse business executives in new ways of thinking to positively impact careers, organizations, and industries, whether you
                                                are an individual looking to enhance your professional development or an organization seeking to collaborate and provide the
                                                business knowledge necessary to help you achieve individual and organization's goals.</p>
                                            <p>The Purpose of the MDPC is to help improve management systems and practices in India by providing relevant training to the
                                                employees across sectors & Institutions of the economy operating at different levels in the organizational hierarchy. The
                                                participants will be equipped with the latest tools, techniques and skills spanning different streams of management such as
                                                General Management, Human Resources, Soft Skills, Marketing and Finance with participatory pedagogy.</p>
                                            <p>The pedagogy adopted is participatory. Our approach is to bring in the rich experience of participants into the class room through
                                                discussions and blend them with inputs from faculty on the latest advancement in the respective field. The case method of study
                                                is supported by major tool. It is supplemented by group exercises, role plays, management games, lectures, and presentations by
                                                participants.</p>
                                            <p>In addition to open MDPs that are attended by executives from different organizations, our institute also develops and a host of
                                                organizes customized sponsored programs based on specific requests. A host or organizations have benefited from such
                                                programs tailor made to their requirements.</p>
                                            <p>Our MDP programs are attended by executives across all levels, ranging from Sales Managers to CEO's.</p>
                                           <p><a href={MDPbrocher} target="_blank"> MDP Brochure</a></p>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab eventKey="Programs" title="Our Programs" className="whiteBackground paddingBottom3em">
                                    <div className="container">
                                        <div className="row">
                                            <h3 className="blockTitle">Our Programs</h3>
                                            <h3 className="blockTitle">A. Outbound Experiential Learning Behavioural Trainings with Pre-Psychometric Tests and Gap Analysis for IDP</h3>
                                             <p>We believe that the key to unlocking your team's full potential lies in gaining self-knowledge, acknowledging strengths and weaknesses, and developing an individual development plan (IDP) to enhance performance. Our programs are designed to incorporate pre psychometric tests, simulation games, and gap analysis, which serve as the foundation for an effective IDP.</p>
                                             <p>Our training programs cover a wide range of areas, including leadership development, managerial development, team building, problem solving, conflict management, and productivity enhancement. Our innovative approach combines theoretical knowledge with practical application, ensuring participants acquire the skills and tools needed to drive exceptional results.</p>
                                             <p><b>Our unique methodology consists of the following three stages:</b></p>

                                             <p>1.Pre-Psychometric Tests for Self-Knowledge: We start by administering psychometric tests that provide participants with a deep understanding of their personality traits, values, and motivation. The results of the tests enable participants to gain valuable self-awareness, identify their strengths and weaknesses, and recognize areas for improvement.</p>
                                             <p>2.Simulation Games for Acknowledgement: Our experiential learning activities involve simulation games that simulate real-life situations, challenges, and opportunities. Participants work in teams to tackle problems, make decisions, and achieve objectives, providing them with a unique opportunity to acknowledge their strengths, leverage their weaknesses, and develop their skills.</p>
                                             <p>Gap Analysis for Individual Development Plan (IDP): Following the simulation games, we conduct a gap analysis to identify areas of improvement and define an individual development plan (IDP) for each participant. The IDP serves as a roadmap for personal and professional development, enabling participants to achieve their full potential and drive exceptional results.</p>

                                             <h3 className="blockTitle">B. NBA Accreditation Training and Development program:</h3>
                                             <p> we have meticulously designed a specialized training program to guide management colleges through the NBA accreditation process. Our team of experienced and renowned faculty members, who have successfully navigated this journey themselves, will provide you with the knowledge, tools, and resources necessary to ensure a smooth and successful accreditation process.</p>
                                             <p><b>Key Highlights:</b></p>
                                             <ol>
                                                <li>Expert Guidance: Our faculty members possess extensive experience in NBA accreditation and will guide you through each step of the process, from self-assessment to submission of the application.</li>
                                                <li>Customized Workshops: We offer tailored workshops and seminars that cover the essential criteria and parameters required for NBA accreditation. These interactive sessions will provide deep insights and practical strategies for meeting the accreditation standards.</li>
                                                <li>Documentation Support: Our team will assist you in preparing the necessary documentation, including the self-assessment report, syllabi, faculty details, and other relevant documents, ensuring compliance with NBA guidelines.</li>
                                                <li>Quality Assurance: We will conduct internal audits to evaluate your college's readiness for accreditation, identifying any gaps and providing recommendations for improvement. This comprehensive quality assurance process will ensure that your institution is well-prepared for the NBA evaluation.</li>
                                                <li>Continuous Support: Our commitment to your college's success extends beyond the training program. We will provide ongoing support and guidance even after the training is completed, ensuring your sustainable progress towards accreditation.</li>

                                             </ol>

                                            <h3 className="blockTitle">C. Domain Trainings: Finance for Non-Finance, Marketing for Non-Marketing, HR for Non-HR, and Operations for Non-Operations</h3>
                                             <p>Our domain trainings are meticulously designed to provide participants with practical knowledge and skills in key business areas, enabling them to make informed decisions and contribute more effectively to your organization's overall success. These programs are specifically tailored for individuals who may not have a background or formal training in a particular domain but are eager to expand their horizons and enhance their professional capabilities.</p>

                                             <p>Here are some key highlights of our domain training programs:</p>
                                             <ol>
                                                <li>Customized Content: Our expert faculty members have carefully curated the training content to ensure it is relevant, practical, and aligned with the specific needs of your organization. The programs are designed to bridge the gap between different departments and foster cross-functional collaboration.</li>
                                                <li>Hands-on Learning: We believe in experiential learning, and our training sessions are highly interactive, incorporating case studies, simulations, group discussions, and real-world examples. Participants will gain a deep understanding of the domain concepts and learn how to apply them in their day-to-day work.</li>
                                                <li>Expert Faculty: Our faculty members are seasoned professionals with extensive industry experience and subject matter expertise. They bring a wealth of practical insights and best practices to the training programs, ensuring participants receive high-quality and up-to-date knowledge.</li>
                                                <li>Flexibility and Convenience: We understand the demands of a busy work schedule, which is why our domain training programs are designed to be flexible. We offer both onsite and online training options, allowing your employees to choose the mode that suits them best. Additionally, the programs can be customized in terms of duration and intensity to meet your specific requirements.</li>
                                                <li>Measurable Impact: We believe in delivering tangible outcomes, and our domain trainings are no exception. We provide pre- and post-training assessments to gauge the participants' progress and measure the impact of the programs. This ensures that the training investment translates into real improvements in knowledge, skills, and performance.</li>

                                             </ol>












                                            {/*------------------------------
                                            <p><b>Indira School of Business Studies successfully completed the first two phases for two batches of Middle & Sr. Managers of Indorama Ventures Ltd. Haldia, West Bengal.</b></p>
                                            <p><b>The IVL Leadership Program is distributed into three phases. </b></p>
                                            
                                                <p>1.Energise Others </p>
                                                <p>2.Champion Change </p>
                                                <p>3.Drive Results </p>
                                             <p>The first two phases  were effectively facilitated from 16th Jan to 21st Jan covering two batches.</p>
                                             <p>The Trainers Dr. Sumit Roy & Dr. Abhijit Bobde use Outdoor experiential learning, Psychometric tests, and Leadership Models for facilitating the program.</p>
                                             <p>The Leadership theme was based on Energising and Change Management.</p>
                                             <p><b>The leadership tools used were:</b></p>
                                             <ul>
                                                <li>Situational Leadership Model</li>
                                                <li>High Performing X Teams Model</li>
                                                <li>Negotiation process and styles</li>
                                                <li>Prioritize (Paretto)</li>
                                                <li>Team building process (Tuckman)</li>
                                                <li>High performing teams (Lencioni model)</li>
                                                <li>Monroe’s motivation sequence</li>
                                                <li>8 steps change model (Kotters) or similar.</li>
                                                <li>Managing complex change (Knoster, Villa and Thousand)</li>
                                            </ul>


                                            <p><b>The Executive Development Programs & Consultancy is delivered through a right blend of Assessments & Employee Development Journey.</b></p>
                                            <p><b>1. Assessment Centre:</b></p>
                                            <p>Psychometric and Action Tests are strategic and psychological tools which are an essential requirement for an organization’s talent management process, starting from talent acquisition to talent development. These strategic tools enhance the chances of organizational success by ensuring that the right fit candidates are hired, identified, and developed for all roles.</p>
                                            <Table className="table table-bordered marginBottom2em" responsive>
                                                <tbody>
                                                    <tr className="titleHraderTable"><td><b>Personality Profiling</b></td><td><b>Aptitude Tests</b></td><td><b>Leadership Tests</b></td></tr>
                                                    <tr><td>MBTI</td><td>IQ Test </td><td>Leadership Behaviour Test</td></tr>
                                                    <tr><td>16PF</td><td>Interest Test</td><td>Leadership Practice Inventory</td></tr>
                                                    <tr><td>DISC</td><td>Verbal and Reasoning Tests</td><td>Leadership Assessment</td></tr>
                                                    <tr><td>Temperament Test</td><td></td><td></td></tr>
                                                    <tr><td>Big 5</td><td></td><td></td></tr>
                                                </tbody>
                                            </Table>
                                            <Table className="table table-bordered" responsive>
                                                <tbody>
                                                    <tr className="titleHraderTable"><td><b>Team Profiling and Teams Test </b></td><td><b>Well Being and Happiness</b></td><td><b>Learning and Development</b></td></tr>
                                                    <tr><td>FIRO - B</td><td>Depression Inventory</td><td>Learning Styles</td></tr>
                                                    <tr><td>Belbin </td><td>Type A and Type B Personality</td><td>Mental Toughness</td></tr>
                                                    <tr><td>Decision Tests</td><td>Happiness Inventory</td><td>Creativity Quotient</td></tr>
                                                    <tr><td>Thomas kilmann Test</td><td>Emotional Intelligence Test</td><td></td></tr>
                                                    <tr><td></td><td>Spiritual Quotient</td><td></td></tr>
                                                </tbody>
                                            </Table>
                                            <br />
                                            <p><b>2. Development Centre:</b></p>
                                            <p>Executive Development is a journey, and it demands diverse inputs. Based on in-depth research and interacting with Executive across the disciplines the following interventions are being developed.</p>
                                            <Table className="table table-bordered" responsive>
                                                <tbody>
                                                    <tr className="titleHraderTable"><td><b>Leadership Development</b></td><td><b>Behavioural &amp; Soft Skills Training</b></td><td><b>Outbound Immersion</b></td><td><b>Domain</b></td></tr>
                                                    <tr><td>Leadership from Tao Philosophy</td><td>Managerial Effectiveness</td><td>Self- Leadership: Manager - Leadership Continuum</td><td>Selling Dynamics</td></tr>
                                                    <tr><td>Quantum Leadership</td><td>Anatomy of Mind</td><td>Team Building/Bonding</td><td>Negotiation- Purchase &amp; Sales</td></tr>
                                                    <tr><td>Neuroscience of Inspirational Leadership</td><td>Management of Productivity</td><td>What am I responsible for? A KRA Approach(Objectives &amp; Self Responsibility)</td><td>Vendor management</td></tr>
                                                    <tr><td>Leadership and Existentialism</td><td>Seven Habits of Highly effective People</td><td>Self and Team Immersion</td><td>TRIZ/Design Thinking</td></tr>
                                                    <tr><td>Leadership lessons from Ancient Indian Wisdom</td><td>Creativity and Innovation</td><td>Interpersonal &amp; Intrapersonal Relationship</td><td>Key Account Management</td></tr>
                                                    <tr><td>Strategy Execution and Jack Welch’s Model of Leadership</td><td>Existentialism and Phenomenalism to deal with Depression and Stress </td><td>Be Pro - Be Productive (Delivering Optimum
                                                        Results)</td><td>Customer Care</td></tr>
                                                    <tr><td>Sustainability Philosophy and Practices</td><td>Game Theory for Personal and Professional Skills</td><td>Work Life Balance</td><td>Data Analytics</td></tr>
                                                </tbody>
                                                    </Table>*/}
                                            <p><b> Blended Assessment and Learning</b></p>
                                            <p>Our Entire delivery process implement assessments that connects the Psychometric Outputs to Strategic Outbound activities visible behaviours and blend both the outcomes and connects it to organizational insights. This process remains as an eye opener where the visible and the invisible in brought on the table for a candid discussion and development.</p>
                                            <p><b>Methodology</b></p>
                                            <ul>
                                                <li>Presentations</li>
                                                <li>Discussions</li>
                                                <li>Activities</li>
                                                <li>Video Cases</li>
                                                <li>Psychometrics</li>
                                                <li>Strategy Games</li>
                                            </ul>
                                            {/*<p><b>4. Consultancy Education</b></p>
                                            <p><b>National Board of Accreditation</b></p>
                                            <p><b>The Program Features:</b></p>
                                            <ul>
                                                <li>Customization: As per the Training Need analysis (TNA) in pace with dynamic business environment</li>
                                                <li>Innovation: Specialized inputs by the subject experts</li>
                                                <li>Value Creation: Effectiveness gauged through Pre & Post Training Assessments</li>
                                                </ul>*/}
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="client" title="OUR CLIENTS" className="whiteBackground paddingBottom3em">
                                    <div className="container">
                                        <div className="row">
                                            <h3 className="blockTitle">OUR CLIENTS</h3>
                                           
                                            <p><b>Corporate:</b></p>
                                            <p>Indorama Ventures Ltd. Haldia, West Bengal, Infocept Ltd. Chennai, KSB Pumps, 
                                           Hi-Tech Radiators, Department of Irrigation, Magnus Styer, L &amp; T, Finolex Industries, FIAT India, Dynalog India, Mecc Alte India, Minda Valeo Security Systems, JCB India, Jabil Circuit, Maruma Consultancy, Racold Thermo, RTC Hotels, Cosmos Bank, Janaseva Sahakari Bank, Janata Sahakari Bank, HDFC, SBI, Cybage, Kalyani Technoforge, Tokri.com and many more...</p>
                                            
                                            <p><b>Institution:</b></p>
                                            <p>Government Polytechnic Nagpur, Rajarambapu Institute Of Technology (RIT), Akemi Business School, Imperial School of Banking &amp; Management Studies (ISBMS), Institute of Business Management and Research Pune, National Insurance Academy (NIA), Export-Import Bank of India (EXIM)</p>
                                            
                                            <p><b>Associations:</b></p>
                                              <Carousel>
                                                <CarouselItem>
                                                <div className="row">
                                                <div className="col-md-3">
                                                <img src={C1Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-3">
                                                <img src={C2Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-3">
                                                <img src={C3Image} alt="" className="imgRes" />
                                            </div>
                                           
                                                <div className="col-md-3">
                                                <img src={C4Image} alt="" className="imgRes" />
                                            </div>
                                            
                                            </div>
                                                </CarouselItem>
                                             
                                         <CarouselItem>
                                            <div class="row">
                                            <div className="col-md-3">
                                                <img src={C5Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-3">
                                                <img src={C6Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={client1} className="img_res" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={client2} className="img_res" />
                                            </div>
                                            </div>
                                         </CarouselItem>
                                            <CarouselItem>
                                             <div className="row">
                                            
                                            <div className="col-md-3">
                                            <img src={client3} className="img_res" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={client4} className="img_res" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={clien5} className="img_res" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={client6} className="img_res" />
                                            </div>
                                            </div>
                                            </CarouselItem>

                                            <CarouselItem>
                                            <div className="row">
                                            <div className="col-md-3">
                                            <img src={client7} className="img_res" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={client8} className="img_res" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={client9} className="img_res" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={client10} className="img_res" />
                                            </div>
                                            
                                            
                                             </div>
                                            </CarouselItem>
                                            <CarouselItem>
                                                <div className="row">
                                                <div className="col-md-3">
                                            <img src={client11} className="img_res" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={client12} className="img_res" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={client4} className="img_res" />
                                            </div>
                                            <div className="col-md-3">
                                            <img src={clien5} className="img_res" />
                                            </div>
                                                </div>
                                            </CarouselItem>
                                           </Carousel>
                                            
                                          
                                             </div>
                                    </div>
                                </Tab>

                                <Tab eventKey="Trainers" title="Trainers" className="whiteBackground paddingBottom3em">
                                    <div className="container paddingclass">
                                        <div className="row">
                                            <h3 className="blockTitle">Our Trainers</h3>
                                            <p>At ISBS we have built a bank of high caliber Practicing Industry managers, Subject Matter Experts across key disciplines and
                                                emerging areas. Cutting across disciplines and different Industry sectors, these practicing managers, academicians and consultants
                                                together, provide with depth and breadth of expertise into our workshops.</p>
                                            <div className="row">
                                                {/* <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={Img1Image} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Mr. B S Guha</b></p>
                                                        <p><b>Consultant (Ex CEO Tata Yazaki Autocomp)</b><br />
                                                            <b>Experience- 40 years</b></p>
                                                        <p className="paddingLeftRight">Retired from the Industry in 2003, having served as the CEO, Tata-Yazaki Autocomp (50-50 JV) for 5 years and 28 years of involvement with Philips India in India and abroad. Graduated from REC, Durgapur (1969, Electrical Engg, 1st Div) and obtained various cretificates in Quality and Operations.
                                                            {GuhaStatement &&
                                                                <span>Have worked with eminent personalities (Prof.C.K.Parahald, Sumantra Ghoshal, Kio Suzaki and others) and consulting groups (BA-H, Gemini, REL etc.) in restructuring of Philips. Currently engaged in lecturing management students in diverse Management Institutes on Strategy, Quality Management & World-class Operations and in advising SMEs.</span>
                                                            }
                                                        </p>
                                                        <div className="readmore1" onClick={() => { setGuhaStatement(!GuhaStatement) }}>{GuhaStatement ? 'Read Less' : 'Read More'}</div>

                                                    </div>
                                                </div> */}
                                                {/* <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={Img2Image} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Dr. Renu Bhargav</b></p>
                                                        <p><b>Director ISBS, Consultant-HR,</b><br />
                                                            <b>Experience 24 Years</b></p>
                                                        <p className="paddingLeftRight">Dr Renu Bhargava pursues her passion for maximizing human potential. As a HR professional, she devoted a decade in creating end-to-end processes in areas of tackling retention, attrition and other HR challenges in managing human asset, for an automated engineering robotic firm in Pune.
                                                            {RenuStatement &&
                                                                <span>Her desire to extend her learning to maximize human potential brought her out from the confines of a single organization to the arena of tackling management development programs and corporate consultancy. Realizing that growth and education share a mutually beneficial relationship saw her taking guest sessions in Industry Bodies and prominent educational institutions in Pune. The realization that a robust framework of technical education is indispensable for the growth of young leaders, she decided to work with the visionary team at Indira Group of Institutes, consolidating quality in the educational framework through achieving approval and accreditation from AICTE for her Institutions. Currently she is the director at Indira School of Business Studies, Pune. Her consultancy assignments took her from Manufacturing to Services and ITES sector.</span>}
                                                        </p>
                                                        <div className="readmore1" onClick={() => { setRenuStatement(!RenuStatement) }}>{RenuStatement ? 'Read Less' : 'Read More'}</div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={Img3Image} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Dr. Komal Singh</b><br/>
                                                        <b>Director- ISBS</b></p>
                                                         <p> <b>  Experience: 20 years of vast experience in the field of Accounting & finance.</b></p>
                                                        <p className="paddingLeftRight">A passionate academician with 20 years <br/>of vast experience in the field of Accounting & finance. Worked in various parts of the country including Gurgaon, Gujarat and Maharashtra. She is an approved Ph.d Guide at Pune University in the area of Financial Management. She has taught Accounting and financial management to BBA, MBA and engineering students also.</p>
                                                        {KomalStatement &&
                                                            <span><p className="paddingLeftRight">Ma’am has completed her graduation and post-graduation in Commerce from Kurukshetra University. Qualified National Eligibility Test (NET) conducted by UGC in 2005. Awarded Ph.D degree in E-Banking in 2009.</p>
                                                                <p className="paddingLeftRight">Published 16 plus research papers in various international and national Journals. She has authored a book on E-Banking in India and presented paper in various International and national conferences and seminars. She has been awarded with Best Paper Award two times in the following International Conferences:</p>
                                                                <p className="paddingLeftRight">1. International conference on Woman in Science & Technology: Creating Sustainable Career (January 28-30, 2016)</p>
                                                                <p className="paddingLeftRight">2. 3rd International Conference on Growth, Globalization& Governance conducted by JK Lakshmipat University, Jaipur, Rajasthan (December 19-21, 2014)</p>
                                                            </span>
                                                        }
                                                        <div className="readmore1" onClick={() => { setKomalStatement(!KomalStatement) }}>{KomalStatement ? 'Read Less' : 'Read More'}</div>  </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={Img4Image} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Dr. Sumit Roy</b></p>
                                                        <p><b>corporate & academics</b><br />
                                                            <b>Total Experience - 30 years (corporate & academics)</b></p>
                                                        <p className="paddingLeftRight">Dr. Sumit Roy is having over thirty years of experience jointly in the Industry and Training. In the industry, he was involved with business development, product management, channel sales, Outbound Training and Psychometric profiling.
                                                            {SumitStatement &&
                                                                <span>His specializations include Eastern and Western philosophy, Cognitive science, Leadership Development, Consciousness, Environmental philosophy, Upanishads, Design Thinking, and Entrepreneurship. He has delivered over Twelve hundred Management Development programs for the executives at the senior level in various organizations. He also has a rich experience of classroom delivery for over twenty thousand hours in Management and Humanities discipline for a diverse range of Executives and students. Sumit has four Masters in humanities and one in Management and his Doctoral thesis was in Applied Ethics. Sumit has published several papers and author of one book titled “Biosphere ethics”. Sumit is an active researcher in cognitive sciences, experiential learnings, consciousness, Indian Scriptures and indigenous knowledge.</span>
                                                            }</p>
                                                        <div className="readmore1" onClick={() => { setSumitStatement(!SumitStatement) }}>{SumitStatement ? 'Read Less' : 'Read More'}</div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={Img5Image} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Dr. Abhijit P Bobde</b></p>
                                                        <p><b>Total Experience - 21 yrs. (Academics & Corporate)</b></p>
                                                        <p className="paddingLeftRight">Abhijit is passionate about training Corporate & individuals towards unlocking their hidden potential. He believes that humans are basically positive, and their productive potential is governed by positive energy which is generated through thoughts & feelings.</p>

                                                        {AbhijitStatement && <span>
                                                            <p className="paddingLeftRight">He facilitates to focus on the following concepts.</p>
                                                            <ul>
                                                                <li>Unlocking Self-Potential/ productivity</li>
                                                                <li>Exploring self-nature by connecting with the real nature</li>
                                                                <li>Accepting Negative Habitual behavior and transforming self to positive behavior by Conscious living concept</li>
                                                                <li>Outdoor training as a vaccine to win over behavioral biases in future life.</li>
                                                            </ul>
                                                            <p className="paddingLeftRight">The Outdoor training and his facilitation connect the experience of participants with the above four concepts resulting in awareness & development of Soft Skills, Life Skills & Behavioral Skills.</p>
                                                            <p className="paddingLeftRight">He got opportunities of launching most of the liabilities, asset & Insurance products (TPP) in banking, as well, have expanded and managed Retail Banking sales for Mumbai & rest of Maharashtra, Goa. He has worked in Product Team as Sr. Manager-Home Loan & Sr. Manager- TPP comprising of Life Insurance, General Insurance & Mutual Fund.</p>
                                                            <p className="paddingLeftRight">Abhijit has a wide experience in different segments such as Academics, Banking, Pharmaceuticals, Events management.</p>
                                                        </span>
                                                        }
                                                        <div className="readmore1" onClick={() => { setAbhijitStatement(!AbhijitStatement) }}>{AbhijitStatement ? 'Read Less' : 'Read More'}</div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={Img6Image} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Prof. Umesh Gramopadhye</b></p>
                                                        <p><b>BA. LLB(Gold), LLM, MPM, MBA</b><br />
                                                            <b>15 plus year in Academic and Industry.</b></p>
                                                        <p className="paddingLeftRight">An Academician, Motivational Speaker, Corporate Trainer He has completed LLB (Gold), LLM, MPM, MBA in Law and HR having wide experience of 15 plus year in Academic and Industry.</p>
                                                        {UmeshStatement &&
                                                            <p className="paddingLeftRight">Umesh is academician, Motivational Speaker, and Corporate Trainer. He provides HR and Law consultation to organizations and provides training on Time Management, Work Life Balance, Connecting to Self. He is conducted various workshops for HR Executives, Blue Collar Employee, Open sessions on “Journey of Happiness” He has been invited as speaker on many issues related to HR and Labour Laws. He is having expertise on life coaching.</p>
                                                        }
                                                        <div className="readmore1" onClick={() => { setUmeshStatement(!UmeshStatement) }}>{UmeshStatement ? 'Read Less' : 'Read More'}</div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={Img7Image} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Dr. Mrityunjay Kumar</b></p>
                                                        <p><b>B.Sc., LLB, MBA, M.Phil., Ph.D.</b><br />
                                                            <b>Marketing Science</b></p>
                                                        <p className="paddingLeftRight">Dr. Mrityunjay is currently working as Assistant Professor in the department of Marketing Management at Indira School of Business Studies, Pune.</p>

                                                        {MrityunjayStatement &&
                                                            <p className="paddingLeftRight">He has over eleven years of experience in corporate and academia. His area of interest lies in Business Analytics, Marketing Research, and Statistical Modeling. He authored eight research papers and a book “Sales Promotion and Brand Equity Perception- Lulu Publication United States (ISBN: 978-1-67801-420-9)”. He is SPSS, R, Excel, Power BI trainer for research and analytics, trained many professionals over the years. Taken FDPs and expert lectures for faculties and students as well.</p>
                                                        }
                                                        <div className="readmore1" onClick={() => { setMrityunjayStatement(!MrityunjayStatement) }}>{MrityunjayStatement ? 'Read Less' : 'Read More'}</div>
                                                    </div>
                                                </div>
                                               
                                                <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={Img9Image} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Dr. Kalpana K. Deshmukh</b></p>
                                                        <p><b>EI Life Coach, Certified HR Coach Professional</b><br />
                                                            <b>Indira School of Business StudiesAsst</b></p>
                                                        <p className="paddingLeftRight">Certified HR Coach and EI Life Coach with 13 years of experience who is well versed given training to the small and large organizations across a range of industry sectors. Additionally, her expertise experience of People Management Skill and Stress management Training helps to build healthy environment at workplace.</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={Img10Image} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Dr.Manisha Maddel</b></p>
                                                        <p className="paddingLeftRight">Dr.Manisha Maddel has a vast experience of 14 years in academics. She has completed her Ph. D in Computer Management. She has done Master’s in Computer Science and MBA in Operations Management.</p>
                                                        {ManishaStatement &&
                                                            <p className="paddingLeftRight">Along with this she is BEC certified and has done diploma in Cyber Law. She is currently working as an Assistant Professor in Indira School of Business Studies. Her interest area includes Business Analytics, Cyber Security, Machine Learning, Artificial Intelligence and Operations Management. She is author of two books related to Human Computer Interface and Cyber Security which are used by Students and Research Scholars. She has written various research papers which are published in renowned journals. She has conducted many workshops for students and faculty members for topics which include Human Computer interface and Cyber Security. Manisha is also interested in Yoga and Cycling and has participated in many events. Recently she completed 100 days cycling challenge by Tourde100.She also participated in 3000 Surya namaskar challenge.</p>

                                                        }
                                                        <div className="readmore1" onClick={() => { setManishaStatement(!ManishaStatement) }}>{ManishaStatement ? 'Read Less' : 'Read More'}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={ManmohanImage} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr. Manmohan Vyas</b></p>
                                                <p><b>Deputy Director- ISBS // Financial Analyst // Consultant</b><br />
                                                    <b>Qualification:PhD. MBA- Finance, MBA - HRM,B.Com.</b><br />
                                                    <b>Industrial Exp: 1 years</b><br />
                                                    <b>Academic Exp: 11 years</b><br />
                                                    <b>Research Papers: 8</b><br />
                                                    {/*<b>Books Written:0</b>*/}
                                                    <p><b>Awards:Academic Jewel 2010, Dewang Mehta B-School Best
                                                        Faculty in FM,2017</b></p>
                                                </p>
                                            </div>
                                        </div>

                                                <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={HetalImage} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Dr. Hetal Bhinde</b></p>
                                                        <p className="paddingLeftRight">Dr. Hetal Bhinde is a highly accomplished faculty member with an impressive educational background and extensive experience in both the industry and education sectors. Holding qualifications such as BSc.</p>
                                                        {HetalStatement &&
                                                            <p className="paddingLeftRight">in Biotechnology, MBA in Production and Materials Management, MBA in International Business Management, PGDIEM, UGC-NET, and a Ph.D. in Commerce and Management, Dr. Bhinde possesses a diverse skill set and a deep understanding of various domains. 
                                                            <br/>
                                                            With seven years of industry experience and twelve years in the field of education, Dr. Bhinde has excelled as a researcher, trainer, entrepreneur, and mentor. Her expertise encompasses areas such as ISO implementation, academic and industry research, and behavioral studies. Dr. Bhinde's keen analytical skills and research capabilities have allowed her to contribute significantly to both academia and industry, making her a valuable asset in bridging the gap between theory and practice.

                                                            <br/>
                                                            As a trainer and mentor, Dr. Bhinde effectively imparts knowledge and fosters professional development among her students and colleagues. Her entrepreneurial mindset and innovative thinking inspire those around her to think outside the box and pursue new avenues for growth. With her extensive qualifications, experience, and exceptional qualities, Dr. Hetal Bhinde continues to make a positive impact in the fields of education and research.
                                                            
                                                            </p>


                                                        }
                                                        <div className="readmore1" onClick={() => { setHetalStatement(!HetalStatement) }}>{HetalStatement ? 'Read Less' : 'Read More'}</div>
                                                    </div>
                                                </div>
                                               {/*} <div className="col-md-6">
                                                    <div className="colClickBox1 minHeight190">
                                                        <div className="clickIconBOx1">
                                                            <img src={Img11Image} alt="" className="clickBoxIcon1" />
                                                        </div>
                                                        <p className="textOrangeColor"><b>Mangesh Sanap</b></p>
                                                        <p className="paddingLeftRight">Mangesh Sanap is a passionate teacher, career counselor and corporate trainer. He has more than two decades of rich experience in teaching, training, and working with corporates. A computer engineer by qualification, has conducted several training programs for engineering and management students for campus placements.</p>
                                                        {MangeshStatement &&
                                                            <p className="paddingLeftRight">He regularly does career counseling for students aspiring for higher and technical education. He has conducted several training programs on Project Management, Six Sigma, Artificial Intelligence, Communication Skills, Case Study Analysis, and Professional Values and Ethics. Recently several colleges have empaneled him for preparation and supervision of the process of NAAC accreditation. He has published three books for MBA students.</p>
                                                        }
                                                        <div className="readmore1" onClick={() => { setMangeshStatement(!MangeshStatement) }}>{MangeshStatement ? 'Read Less' : 'Read More'}</div>
                                                    </div>
                                                    </div>*/}
                                                    
                                                <div className="col-md-6">
                                                    <div className="minHeight400 blueBoxPatch text-center">
                                                        <img src={ImgNbaImage} alt="" className="width80" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>


                                
                                <Tab eventKey="Clients" title="Clients Testimonials" className="whiteBackground paddingBottom3em">
                                    <div className="container">
                                        <div className="row">
                                            <h3 className="blockTitle text-center paddingBottom1em paddingTop1em">Clients Testimonials</h3>
                                            <div className="col-md-6 marginBottom30px">
                                                <div className="boxShadow padding15px text-center boxStyle11">
                                                    <p className="orangeText"><b>Shivanand B. Madiwalar (Finolex Industries Ltd.)</b></p>
                                                    <p>“First and foremost, I would like to thank the Finolex Management and Indira School of Business Studies for providing an opportunity to learn various aspects of “Leadership Development programs”. This program gives an edge to uphold management ideasin Decision Making and Team Work and taught how

                                                        to be united in different critical situations with an example of movies like “Lagan” or “Chak de”. Planning and Leadership programs prompted to think beyond your limitation and go for out of box thinking”.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 marginBottom30px">
                                                <div className="boxShadow padding15px text-center boxStyle11">
                                                    <p className="orangeText"><b>Yogesh Goyal, Director, RTC Hotels Pvt. Ltd.</b></p>
                                                    <p>“We would like to take this opportunity to thank Indira School
                                                        Business Studies (ISBS), Pune, for carrying out the business
                                                        consulting project on "Optimizing the tenant mix for our
                                                        commercial space" for us in satisfactory manner. It has been
                                                        very nice level of professional approach to the project.
                                                    </p>
                                                    <p>We are looking forward to continued association with ISBS in future for upcoming projects."</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 marginBottom30px">
                                                <div className="boxShadow padding15px text-center boxStyle11">
                                                    <p className="orangeText"><b>Kiran Kalyanrao Guravpatil (Finolex Industries Ltd.)</b></p>
                                                    <p>“Attending LDP (Leadership Development Programs) has been
                                                        a very good experience. The subjects selected for this program
                                                        have helped us in our daily working life. Subjects like
                                                        “Communication” and “Team Building” are a part of our routine
                                                        work.
                                                    </p>
                                                    {KiranStatement &&
                                                        <span><p>Important subjects like “Conflict Management”, “Change Management”, “Ethics & Values”, and “Decision Making” are very practical subjects and have been conducted very well.</p>
                                                            <p>Definitely LDP has helped us to improve our efficiency and also makes us responsible towards our organization”.</p></span>
                                                    }
                                                    <div className="readmore1" onClick={() => { setKiranStatement(!KiranStatement) }}>{KiranStatement ? 'Read Less' : 'Read More'}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 marginBottom30px">
                                                <div className="boxShadow padding15px text-center boxStyle11">
                                                    <p className="orangeText"><b>D. K. Purandare, Principal Training, Janseva Sahakari Bank Ltd.</b></p>
                                                    <p>“At the outset, kindly accept our heartfelt appreciation for the
                                                        excellent manner in which the “Business Development”
                                                        programmed was conducted by your faculties. Almost all the
                                                        participants attending the program have lauded all three
                                                        faculties.</p>
                                                    {PurandareStatement &&
                                                        <p>In regards to the above, our bank would like to consider similar training program for the branch managers”.</p>
                                                    }
                                                    <div className="readmore1" onClick={() => { setPurandareStatement(!PurandareStatement) }}>{PurandareStatement ? 'Read Less' : 'Read More'}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 marginBottom30px">
                                                <div className="boxShadow padding15px text-center boxStyle11">
                                                    <p className="orangeText"><b>Yogesh P. Mungi (Finolex Industries Ltd.)</b></p>
                                                    <p>“Leadership Development Program is beautifully planned in 12 squares. The 12 squares (topics) are very important and helpful in our professional as well as personal life”.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 marginBottom30px">
                                                <div className="boxShadow padding15px text-center boxStyle11">
                                                    <p className="orangeText"><b>Prashant Suresh Telang, Hi-tech Radiators</b></p>
                                                    <p>Successfully completed two day "Outbound Leadership Training" facilitated by " Hi Tech Radiators Pvt Ltd " Executed by " Indira School of Business Studies "</p>
                                                    <p>Thanks to Management of Hi-Tech Radiators & Dr. Sumit Roy Dr. Abhijit Bobde Mr. Hemraj Patle from Indira School of Business Studies for their wonderful training sessions.</p>
                                                    {PrashantStatement &&
                                                        <span><p>Every Corporate must organize such kind of Training to their HODs for Integration, Team Building, Goal Mapping, Self-Concentration , Planning & Negotiation , Analysing & Visualizing , Trust & Coordination , Plan Do , Check , Act , Monitor Evaluator . Team Indira School of Business Studies you are too good.</p>
                                                            <p>Thanks Prashant Suresh Telang</p></span>
                                                    }
                                                    <div className="readmore1" onClick={() => { setPrashantStatement(!PrashantStatement) }}>{PrashantStatement ? 'Read Less' : 'Read More'}</div>
                                                </div>
                                            </div>
                                            {/*<h3 className="blockTitle text-center paddingBottom1em paddingTop1em">Our Clients</h3>*/}
                                            
                                        </div>

                                    </div>
                                </Tab>
                                
                                <Tab eventKey="Gallery" title="Gallery" className="whiteBackground paddingBottom3em galleryTab">
                                    <div className="container paddingclass">
                                        <div className="row">
                                            <h3 className="blockTitle text-center paddingBottom1em paddingTop1em">Gallery</h3>
                                            <div className="col-md-4 col-6">
                                                <img src={Imgmdp1} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Imgmdp3} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Imgmdp2} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Imgmdp4} alt="" className="imgRes" />
                                            </div>
                                           
                                            <div className="col-md-4 col-6">
                                                <img src={Imgmdp6} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Imgmdp5} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img111Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img12Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img13Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img14Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img15Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img16Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img17Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img18Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img19Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img20Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img21Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img22Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img23Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img24Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img25Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img26Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img27Image} alt="" className="imgRes" />
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <img src={Img28Image} alt="" className="imgRes" />
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Contact" title="Contact Us" className="whiteBackground paddingBottom3em">
                                <div className="container paddingclass">
                                        <div className="row">
                                        <h3 className="blockTitle">Contact Us</h3>
                                        <p><b>Sr. No. 85/5B, 6D/1 and 6D/2, Unity Campus,</b></p>
                                        <p><b>New Pune Mumbai Highway, Tathawade,</b></p>
                                        <p>Pune 411033, Maharashtra</p>
                            
                                        <br/>
                                        <br/>
                                        <p><b>Phone No.:</b></p>
                                        <p>+91-20-66759500 / 415 / 404</p>
                                        <br/>
                                        <br/>
                                        <p><b>In-Charge Contact:</b></p>
                                        <p><b>Dr. Abhijit P. Bobde</b></p>
                                        <p><a href="mailto:abhijit.bobde@indiraisbs.ac.in"  style={{color:"#000"}}>abhijit.bobde@indiraisbs.ac.in</a></p>
                                        <p><a href="tel:9769211665" style={{color:"#000"}}>9769211665</a></p>
                                        <p><b>EMAIL:</b><a href="mailto:mdp@indiraisbs.ac.in" style={{color:"#000"}}>mdp@indiraisbs.ac.in</a> </p>
                                    </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default Mdp