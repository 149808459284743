import React, { Fragment, useState, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/management-banner.jpg"
import TaritaImage from "../../assets/images/tarita2.jpg"
import ChetanImage from "../../assets/images/chetan-sir.jpg"
import RenuImage from "../../assets/images/renuma'am.jpg"
import YogeshImage from "../../assets/images/yogeshmahajn.jpg"
import NatashaImage from "../../assets/images/natasha-1.jpg"

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
const IndiraManagementReview = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout rightAlignBannerTxt whiteText">Indira <br />Management Review</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Research &#62; <span>Indira Management Review</span></h3>
                    </div>
                </div>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="row paddingTop3em  paddingBottom3em">
                        <div className="col-md-12">
                            <Tabs
                                defaultActiveKey="About"
                                id="uncontrolled-tab-example"
                                className="barColor col-4t transparentBackground"
                            >
                                <Tab eventKey="About" title="About IMR" className="whiteBackground paddingBottom3em">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="blockTitle">About IMR</h3>
                                                <p>Indira Management Review is a national annual peer reviewed journal published by Shree Chanakya Education Society’s Indira School of Business Studies, which is one of the top management colleges in Pune. It is a quality research journal and it endeavors to provide forum for academicians and industry participants who are interested in the discussion of current and future issues as well as challenges impacting the globe. IMR focuses on promoting and disseminating relevant, high quality research in the field of management.<b>IMR encourages new ideas and perspectives on existing research. Manuscripts that are suitable for publication in IMR cover domains such as:</b></p>
                                                <ul className="reg">
                                                    <li>Business Strategy and Policy</li>
                                                    <li>IT Sourcing</li>
                                                    <li>Entrepreneurship</li>
                                                    <li>Human Resource Management</li>
                                                    <li>Financial Management</li>
                                                    <li>Organizational Behavior</li>
                                                    <li>Marketing Management and Research</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Patrons" title="Our Patrons" className="whiteBackground paddingBottom3em">
                                    <div className="container">
                                        <div className="row">
                                            <h3 className="blockTitle">Our Patrons</h3>
                                            <div className="col-md-4">
                                                <div className="boxShadow paddingclass">
                                                    <img src={TaritaImage} alt="" className="imgRes" />
                                                    <br />
                                                    <br />
                                                    <p className="textOrangeColor"><b>Dr. Tarita Shankar</b></p>
                                                    <p>Chairperson, Indira Group of Institutes, Pune</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="boxShadow paddingclass">
                                                    <img src={ChetanImage} alt="" className="imgRes" />
                                                    <br />
                                                    <br />
                                                    <p className="textOrangeColor"><b>Prof. Chetan Wakalkar</b></p>
                                                    <p>Group Director Indira Group of Institutes, Pune</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="boxShadow paddingclass">
                                                    <img src={RenuImage} alt="" className="imgRes" />
                                                    <br />
                                                    <br />
                                                    <p className="textOrangeColor"><b>Dr. Renu Bhargava</b></p>
                                                    <p>Director Indira School of Business Studies, Pune</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Editors" title="Editors" className="whiteBackground paddingBottom3em">
                                    <div className="container">
                                        <div className="row">
                                            <h3 className="blockTitle paddingBottom1em paddingTop1em">Editors</h3>
                                            <div className="col-md-4">
                                                <div className="boxShadow paddingclass">
                                                    <img src={YogeshImage} alt="" className="imgRes" />
                                                    <br />
                                                    <br />
                                                    <p className="textOrangeColor"><b>Dr. Yogesh Mahajan</b></p>
                                                    <p>Associate Professor – Indira School of Business Studies Pune<br />
                                                        Managing Editor<br />
                                                        Email id: imreditor@indiraisbs.ac.in<br />
                                                        yogesh.mahajan@indiraisbs.ac.in<br />
                                                        Phone: 020-66759422</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="boxShadow paddingclass">
                                                    <img src={NatashaImage} alt="" className="imgRes" />
                                                    <br />
                                                    <br />
                                                    <p className="textOrangeColor"><b>Dr. Natasha Kaul</b></p>
                                                    <p>Associate Professor – Indira School of Business Studies Pune<br />
                                                        Managing Editor<br />
                                                        Email id: natasha.kaul@indiaisbs.ac.in<br />
                                                        Contact No.: 020-66759431</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="PublisherDetails" title="Publisher Details" className="whiteBackground paddingBottom3em">
                                    <div className="container paddingclass">
                                        <div className="row">
                                            <h3 className="blockTitle">Publisher Details</h3>
                                            <p><b>Name of the Publisher: Shree Chanakya Education Society’s Indira School of Business Studies, Pune</b></p>
                                            <p><b>Place of Publication:</b> Pune, Maharashtra, India</p>
                                            <p><b>Email id:</b> imreditor@indiraisbs.ac.in</p>
                                            <p><b>Postal Address:</b> Sr. No. 85/5B, 6D/1 and 6D/2, Unity Campus,
                                                New Pune Mumbai Highway, Tathawade,
                                                Pune 411033, Maharashtra</p>
                                            <p>For more details please visit: http://isbsimr.com/index.php/imr/index</p>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default IndiraManagementReview